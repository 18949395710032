import React, { useContext } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Statistics from "./Statistics"

import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsCerradas from "./TicketsCerradas"


const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 120,
    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
}))

const Dashboard = () => {
    const classes = useStyles()

    const { user } = useContext(AuthContext);
    var userQueueIds = [];

    if (user.queues && user.queues.length > 0) {
        userQueueIds = user.queues.map(q => q.id);
    }

    const GetTickets = (status, showAll, withUnreadMessages) => {

        const { count } = useTickets({
            status: status,
            showAll: showAll,
            withUnreadMessages: withUnreadMessages,
            queueIds: JSON.stringify(userQueueIds)
        });
        return count;
    }

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Paper>
                        <Grid item xs={17}>
                            <Paper>
                                <Statistics />
                            </Paper>
                        </Grid>
                        <Grid item xs={17}>
                            <Paper>
                                <TicketsCerradas />
                            </Paper>
                        </Grid>
                    </Paper>

                </Grid>
            </Container>
        </div>
    )
}

export default Dashboard